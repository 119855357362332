import { useOktaAuth } from "@okta/okta-react";
import { Button } from "@mui/material";
// import { useNavigate } from 'react-router-dom';

// Basic component with logout button
function Logout() {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    // Log out of Okta
    await oktaAuth.signOut();
  };

  return <Button onClick={logout}>Logout</Button>;
}

export default Logout;
