import {
  Backdrop,
  CircularProgress,
  Grid2,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { styles } from "../Downloader/styles";
import { useState } from "react";
import {
  DownloaderProps,
  MediaRecord,
  Package,
  Product,
} from "./DownloaderInterfaces";
import ProductService from "../../services/ProductService";
import { BITRATE_64_KBPS, MP3_CODEC } from "../../utils/AudioFormatConstants";
import PackageService from "../../services/PackageService";
import { Add, Delete } from "@mui/icons-material";
import { AlertMessage } from "../Home/Home";
import { HttpStatus } from "../../utils/HttpCommon";

const Downloader = ({ setAlertList }: DownloaderProps) => {
  const getEmptyProduct = () => {
    return {
      id: 0,
      productCode: "",
      language: "",
      stocknumber: "",
      version: "",
      mediaId: "",
      mediaType: "",
      encryptionType: "",
      bibleId: "",
    };
  };

  const [isFetchingProduct, setIsFetchingProduct] = useState<boolean>(false);
  const [isFetchingPackage, setIsFetchingPackage] = useState<boolean>(false);
  const [packageRequest, setPackageRequest] = useState<Package>({
    deviceType: "None",
    productList: [getEmptyProduct()],
  });

  const encryptionTypes: string[] = [
    "2SMS - Proclaimers",
    "TO - Proclaimers",
    "TO - Acclaimers",
    "TO - Biblestick",
    "None",
  ];

  const VALID_PRODUCT_CODE_LENGTH = 4;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    changedIndex: number,
  ) => {
    if (event.target.value.includes("%")) {
      showMessage("Wildcards (%) are not valid. Please use the Product Code.");
      return;
    }

    setPackageRequest((prevState: Package) => {
      let packageCopy = { ...prevState };
      if (packageCopy) {
        packageCopy.productList[changedIndex].productCode =
          event.target.value.toUpperCase();
      }
      return packageCopy;
    });
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPackageRequest((prevState: Package) => {
      let packageCopy = { ...prevState };
      if (packageCopy) {
        packageCopy.deviceType = event.target.value;
      }

      return packageCopy;
    });
  };

  const getMatchingMediaRecord = (mediaRecordList: MediaRecord[]) => {
    const matchingMediaRecord = mediaRecordList.find(
      (mediaRecord: MediaRecord) =>
        mediaRecord.codec === MP3_CODEC &&
        mediaRecord.bitrate === BITRATE_64_KBPS,
    );

    return matchingMediaRecord;
  };

  const getProduct = (
    event:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    //clear table fields
    setPackageRequest((prevState: Package) => {
      let packageCopy = { ...prevState };
      if (packageCopy) {
        const productCode = packageCopy.productList[index].productCode;
        packageCopy.productList[index] = getEmptyProduct();
        packageCopy.productList[index].productCode = productCode;
      }
      return packageCopy;
    });

    if (packageRequest.productList[index].productCode === "") {
      return;
    } else if (
      packageRequest.productList[index].productCode.length > 0 &&
      packageRequest.productList[index].productCode.length <
        VALID_PRODUCT_CODE_LENGTH
    ) {
      showMessage(
        `Product code must contain at least ${VALID_PRODUCT_CODE_LENGTH} characters.`,
      );
      return;
    }

    setIsFetchingProduct(true);
    ProductService.getMediaRecords(
      packageRequest.productList[index].productCode,
    )
      .then((result) => {
        const mediaRecord = getMatchingMediaRecord(result);
        setPackageRequest((prevState: Package) => {
          let packageCopy = { ...prevState };
          if (packageCopy && mediaRecord) {
            packageCopy.productList[index].language = mediaRecord.language;
            packageCopy.productList[index].version = mediaRecord.version;
            packageCopy.productList[index].stocknumber =
              mediaRecord.stocknumber;
            packageCopy.productList[index].mediaId = mediaRecord.media_id;
            packageCopy.productList[index].mediaType = mediaRecord.media_type;
            packageCopy.productList[index].bibleId = mediaRecord.bible_id;
          }

          return packageCopy;
        });
      })
      .catch((error) => {
        if (error.response.status === HttpStatus.StatusNotFound) {
          showMessage("Product not found.");
        } else if (error.response.status === HttpStatus.StatusBadRequest) {
          showMessage(error.response.data.error);
        } else {
          showMessage("There was an error retrieving the product.");
        }
        console.log(error);
      })
      .finally(() => {
        setIsFetchingProduct(false);
      });
  };

  const addProductRow = () => {
    if (packageRequest.productList.length < 4) {
      setPackageRequest((prevState: Package) => {
        let packageCopy = { ...prevState };
        packageCopy.productList.push(getEmptyProduct());

        return packageCopy;
      });
    }
  };

  const removeProductRow = (index: number) => {
    if (packageRequest.productList.length === 1) {
      setPackageRequest((prevState: Package) => {
        let packageCopy = { ...prevState };
        packageCopy.productList = [getEmptyProduct()];

        return packageCopy;
      });
    } else {
      setPackageRequest((prevState: Package) => {
        let packageCopy = { ...prevState };
        packageCopy.productList.splice(index, 1);

        return packageCopy;
      });
    }
  };

  const downloadPackage = () => {
    setIsFetchingPackage(true);
    PackageService.getDownload(packageRequest)
      .then((result: any) => {
        showMessage("Package downloaded.", "success");
        window.location.assign(result.data.cdnLink);
      })
      .catch((error) => {
        if (error.response.status === HttpStatus.StatusBadRequest) {
          showMessage("No media found for requested product code.");
        } else {
          showMessage("There was an error downloading the package.");
        }
        console.log(error);
      })
      .finally(() => {
        setIsFetchingPackage(false);
      });
  };

  const downloadCopyright = () => {
    setIsFetchingPackage(true);
    PackageService.getDownload(packageRequest)
      .then((result: any) => {
        showMessage("Copyright downloaded.", "success");
        window.location.assign(result.data.copyrightLink);
      })
      .catch((error) => {
        if (error.response.status === HttpStatus.StatusBadRequest) {
          showMessage("No media found for requested product code.");
        } else {
          showMessage("There was an error downloading the copyright.");
        }
        console.log(error);
      })
      .finally(() => {
        setIsFetchingPackage(false);
      });
  };

  const showMessage = (message: string, type: string = "error") => {
    setAlertList((prev: AlertMessage[]) => {
      return [
        ...prev,
        {
          message: message,
          key: new Date().getTime(),
          type: type,
        },
      ];
    });
  };

  return (
    <>
      <Backdrop sx={styles.backdrop} open={isFetchingProduct}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop sx={styles.backdrop} open={isFetchingPackage}>
        <Card sx={{ maxWidth: "350px" }}>
          <CardContent>
            <Typography variant="h5" sx={{ marginBottom: "20px" }}>
              Downloading
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: "20px" }}>
              It may take a few minutes to download the requested package.
              Please don't refresh.
            </Typography>
            <LinearProgress sx={{ maxWidth: "80%", marginLeft: "10%" }} />
          </CardContent>
        </Card>
      </Backdrop>
      <Grid2 container>
        <Grid2 size={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.noWordBreak}>Product Code</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell sx={styles.noWordBreak}>
                    Stock&nbsp;Number
                  </TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell sx={styles.noWordBreak}>Media ID</TableCell>
                  <TableCell>
                    {packageRequest.productList.length < 4 && (
                      <Tooltip title="Add Row">
                        <IconButton onClick={() => addProductRow()}>
                          <Add color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packageRequest.productList.map(
                  (product: Product, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={styles.rightBorder}>
                        <TextField
                          autoFocus
                          value={product.productCode || ""}
                          onChange={(event) => handleInputChange(event, index)}
                          name="productCode"
                          size="small"
                          onBlur={(event) => getProduct(event, index)}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              getProduct(event, index);
                            }
                          }}
                          sx={{ width: "200px" }}
                        />
                      </TableCell>
                      <TableCell sx={styles.rightBorder}>
                        {product.language}
                      </TableCell>
                      <TableCell sx={styles.rightBorder}>
                        {product.stocknumber}
                      </TableCell>
                      <TableCell sx={styles.rightBorder}>
                        {product.version}
                      </TableCell>
                      <TableCell sx={styles.rightBorder}>
                        {product.mediaId}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete Row">
                          <IconButton onClick={() => removeProductRow(index)}>
                            <Delete color="primary" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
        <Grid2
          size={12}
          style={{
            display: "flex",
            justifyContent: "right",
            gap: "10px",
            padding: "20px 0",
          }}
        >
          <TextField
            select
            size="small"
            label="Encryption Type"
            value={packageRequest.deviceType}
            onChange={(event) => {
              handleDropdownChange(event);
            }}
            sx={{ padding: "0px", width: "200px" }}
            component={Paper}
            variant="outlined"
            fullWidth
          >
            {encryptionTypes.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Divider orientation="vertical" sx={{ margin: "0 20px" }} />
          <Button variant="outlined" onClick={downloadPackage}>
            Download Package
          </Button>
          <Button variant="outlined" onClick={downloadCopyright}>
            Download Copyright
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};

export default Downloader;
