const ISSUER = process.env.REACT_APP_OAUTH_ISSUER ?? "";
const CLIENT_ID = process.env.REACT_APP_BIBLEBRAIN_WEB_OKTA_CLIENT_ID ?? "";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK ?? false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

if (!ISSUER || !CLIENT_ID) {
  throw new Error(
    "Missing required environment variables: REACT_APP_OAUTH_ISSUER or REACT_APP_BLIMP_WEB_OKTA_CLIENT_ID",
  );
}

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    postLogoutRedirectUri: `${window.location.origin}/`,
  },
  resourceServer: {
    // port: 3000,
    // statusPath: '/status',
    // packagePath: '/package',
    // productPath: '/product',
  },
};
