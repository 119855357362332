import { getClient } from "../utils/HttpCommon";
import { Package } from "../views/Downloader/DownloaderInterfaces";

export interface PackageTypeRequest {
  cdnLink: string;
  message: string;
}

export interface PackageResponse {
  cdnLink: string;
  copyrightLink: string;
  message: string;
  error: string | null;
}

const http = getClient(process.env.REACT_APP_API_GATEWAY);

const getDownload = async (params: Package): Promise<PackageResponse> => {
  const response = await http.post<PackageTypeRequest, PackageResponse>(
    `/package`,
    params,
  );

  return response;
};

const PackageService = {
  getDownload,
};

export default PackageService;
