import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  rightBorder: {
    borderRight: "1px solid " + customTheme.palette.grey["100"],
  },
  noWordBreak: {
    wordBreak: "unset",
  },
};
