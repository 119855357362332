import http from "../utils/HttpCommon";
import { MediaRecord } from "../views/Downloader/DownloaderInterfaces";

export interface MediaRecordsResponse {
  data: MediaRecords;
}

export interface MediaRecords {
  media_records: MediaRecord[];
}

const getMediaRecords = async (productCode: string): Promise<MediaRecord[]> => {
  const response = await http.get<MediaRecordsResponse>(
    `/product?productCode=${productCode}`,
  );

  const { data } = response;
  const { data: mediaRecords } = data;
  const { media_records: mediaList } = mediaRecords;

  return mediaList;
};

const ProductService = {
  getMediaRecords,
};

export default ProductService;
