import { createTheme, Theme } from "@mui/material/styles";

export const BORDER_COLOR = "#E1E6ED";
export const MAIN_COLOR = "#AA182C";
export const MAIN_COLOR_RED = "#AA182C";
export const MAIN_INPUT_TEXT_COLOR = "#242833";
export const MAIN_PLACEHOLDER_TEXT_COLOR = "#9BAFC4";

let customTheme = createTheme();

export const pxToRem = customTheme.typography.pxToRem;

customTheme = createTheme(customTheme, {
  palette: {
    primary: {
      main: MAIN_COLOR,
      light: "#D94156",
      dark: "#800D1C",
    },
    secondary: {
      main: "#657B92",
      light: "#9BAFC4",
      dark: "#4B5E73",
    },
    text: {
      primary: MAIN_INPUT_TEXT_COLOR,
      secondary: "#68728C",
    },
    background: {
      default: "#f2f6fa",
    },
  },
  typography: {
    fontFamily: "'Inter'",
    button: {
      fontFamily: "'Inter'",
      textTransform: "capitalize",
    },
    body1: {
      fontFamily: "'Inter'",
    },
    h1: {
      fontFamily: "'Josefin Sans'",
      fontStyle: "normal",
      color: MAIN_COLOR,
    },
    h2: {
      fontFamily: "'Inter'",
      color: MAIN_COLOR,
    },
    h3: {
      fontFamily: "'Inter'",
      color: MAIN_COLOR,
    },
    h6: {
      fontFamily: "'Inter'",
      color: MAIN_INPUT_TEXT_COLOR,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) =>
          theme.unstable_sx({
            backgroundColor: theme.palette.common.white,
            "& .MuiOutlinedInput-root": {
              padding: `${pxToRem(4.5)}`,
            },
          }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) =>
          theme.unstable_sx({
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: BORDER_COLOR,
              },
            },
            "& .MuiInputBase-multiline": {
              padding: 0,
            },
            "& .MuiInputLabel-root.MuiFormLabel-root": {
              left: pxToRem(10),
              top: pxToRem(2),
              color: MAIN_PLACEHOLDER_TEXT_COLOR,
            },
            "& .MuiFormLabel-root.Mui-focused": {
              left: 0,
            },
            "& .MuiFormLabel-root.MuiFormLabel-filled": {
              left: 0,
            },
            "& .MuiFormLabel-root": {
              fontSize: pxToRem(15),
              lineHeight: pxToRem(24),
            },
          }),
      },
    },
  },
});

export const theme = customTheme;
