import axios from "axios";

export const getClient = (
  baseURL: string = `${process.env.REACT_APP_API_GATEWAY}`,
) => {
  return axios.create({
    baseURL,
    headers: {
      "Content-type": "application/json",
    },
  });
};

export const HttpStatus = {
  StatusOK: 200,
  StatusBadRequest: 400,
  StatusNotFound: 404,
  StatusInternalServerError: 500,
};

export default getClient();
