import { Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Grid2 } from "@mui/material";
import { theme } from "./utils/CustomTheme";
import Home from "./views/Home/Home";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./Components/SecureRoute";
import { Security } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import Loading from "./Components/Loading";
import config from "./config";
import { useEffect } from "react";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
  });

  return (
    <ThemeProvider theme={theme}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Container className="App">
          <Grid2 container>
            <Grid2 size={12}>
              <Routes>
                <Route
                  path="login/callback"
                  element={<LoginCallback loadingElement={<Loading />} />}
                />
                <Route path="/" element={<RequiredAuth />}>
                  <Route path="" element={<Home />} />
                </Route>
              </Routes>
            </Grid2>
          </Grid2>
        </Container>
      </Security>
    </ThemeProvider>
  );
}

export default App;
